@font-face {
  font-family: 'EssenThiUltCn';
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src:
    url('../assets/fonts/EssenThiUltCn.woff') format('woff'),
    url('../assets/fonts/EssenThiUltCn.woff2') format('woff2');
}
@font-face {
  font-family: UniversLTStd;
  font-style: normal;
  fontd-display: swap;
  font-weight: 400;
  src:
    url('../assets/fonts/UniversLTStd.woff') format('woff'),
    url('../assets/fonts/UniversLTStd.woff2') format('woff2');
}
@font-face {
  font-family: UniversLTStd-Cn;
  font-style: normal;
  fontd-display: swap;
  font-weight: 400;
  src:
    url('../assets/fonts/UniversLTStd-Cn.woff') format('woff'),
    url('../assets/fonts/UniversLTStd-Cn.woff2') format('woff2');
}
@font-face {
  font-family: UniversLTStd-Bold;
  font-style: normal;
  fontd-display: swap;
  font-weight: 700;
  src:
    url('../assets/fonts/UniversLTStd-Bold.woff') format('woff'),
    url('../assets/fonts/UniversLTStd-Bold.woff2') format('woff2');
}
@font-face {
  font-family: UniversLTStd-ThinUltraCn;
  font-style: normal;
  fontd-display: swap;
  font-weight: 100;
  src:
    url('../assets/fonts/UniversLTStd-ThinUltraCn.woff') format('woff'),
    url('../assets/fonts/UniversLTStd-ThinUltraCn.woff2') format('woff2');
}
@font-face {
  font-family: UniversLTStd-UltraCn;
  font-style: normal;
  fontd-display: swap;
  font-weight: 400;
  src:
    url('../assets/fonts/UniversLTStd-UltraCn.woff') format('woff'),
    url('../assets/fonts/UniversLTStd-UltraCn.woff2') format('woff2');
}
@font-face {
  font-family: PlantinMTPro-Light;
  font-style: normal;
  fontd-display: swap;
  font-weight: 300;
  src:
    url('../assets/fonts/PlantinMTPro-Light.woff') format('woff'),
    url('../assets/fonts/PlantinMTPro-Light.woff2') format('woff2');
}
@font-face {
  font-family: PlantinMTPro-LightItalic;
  font-style: normal;
  fontd-display: swap;
  font-weight: normal;
  src:
    url('../assets/fonts/PlantinMTPro-LightItalic.woff') format('woff'),
    url('../assets/fonts/PlantinMTPro-LightItalic.woff2') format('woff2');
}
@font-face {
  font-family: PlantinMTPro;
  font-style: normal;
  fontd-display: swap;
  font-weight: 400;
  src:
    url('../assets/fonts/PlantinMTPro-Regular.woff') format('woff'),
    url('../assets/fonts/PlantinMTPro-Regular.woff2') format('woff2');
}
@font-face {
  font-family: PlantinMTPro-Bold;
  font-style: normal;
  fontd-display: swap;
  font-weight: 700;
  src:
    url('../assets/fonts/PlantinMTPro-Bold.woff') format('woff'),
    url('../assets/fonts/PlantinMTPro-Bold.woff2') format('woff2');
}

@font-face {
  font-family: PlantinMTPro-BoldCn;
  font-style: normal;
  fontd-display: swap;
  font-weight: 700;
  src:
    url('../assets/fonts/PlantinMTPro-BoldItalic.woff') format('woff'),
    url('../assets/fonts/PlantinMTPro-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: LouizeDisplay;
  font-style: normal;
  fontd-display: swap;
  font-weight: 700;
  src:
    url('../assets/fonts/Louize Display - Regular-205TF.woff') format('woff'),
    url('../assets/fonts/Louize Display - Regular-205TF.woff2') format('woff2');
}
