:root {
  --primary-background-color: #ffffff;
  --secondary-background-color: #c2c2c2;
  --highlight-background-color: #e5e5e5; // active bg
  --disabled-background-color: #8d8d8d; // inactive bg

  // Borders
  --primary-border-color: #4d4d4d; // ::active, ::focus
  --secondary-border-color: #8d8d8d; // inactive
  --terciary-border-color: #b3b3b3; // inactive
  --error-border-color: #c60000;

  // Swiper
  --swiper-pagination-color: white;
  --swiper-navigation-color: white;

  // Texts
  --primary-text-color: #303030;
  --secondary-text-color: #4a4a4a;
  --terciary-text-color: #707070;
  --light-text-color: #fff;
  --logo-color: #666666;
  --error-text-color: #c60000;

  --white: #fff;
  --success: #7bc77b;
  --amex: #0160b6;

  // Font Family
  --primary-font-family: 'PlantinMTPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --serif-headings: 'LouizeDisplay', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --primary-bold-condensed-font-family: 'PlantinMTPro-BoldCn', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --primary-italic-font-family: 'PlantinMTPro-LightItalic', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --secondary-font-family: 'UniversLTStd', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --secondary-font-family-bold: 'UniversLTStd-Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --secondary-font-family-condensed: 'UniversLTStd-Cn', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --secondary-font-family-ultra-condensed: 'UniversLTStd-UltraCn', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --secondary-font-family-thin-ultra-condensed: 'UniversLTStd-ThinUltraCn', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --essentialist-font-family: EssenThiUltCn, Arial, sans-serif;

  // Font Sizes
  --font-super-tiny: 0.5rem; // 8px
  --font-tiny: 0.5625rem; // 9px
  --font-xxxs: 0.625rem; // 10px
  --font-xxs: 0.6875rem; // 11px
  --font-xs: 0.75rem; // 12px
  --font-s: 0.8125rem; //13px
  --font-sm: 0.875rem; // 14px
  --font-normal: 0.9375rem; // 15px
  --font-base: 1rem; // 16px
  --font-m: 1.125rem; // 18px
  --font-l: 1.25rem; // 20px
  --font-l-0: 1.375rem; // 22px
  --font-l-1: 1.5rem; // 24px
  --font-l-2: 1.625rem; // 26px
  --font-l-3: 1.75rem; // 28px
  --font-xl: 1.875rem; // 30px
  --font-xl-2: 2.125rem; // 34px
  --font-2xl: 2.75rem; //44px
  --font-3xl: 3.75rem; // 60px
  --font-pre-huge: 6.625rem; // 106px
  --font-huge: 7.813rem; // 125px
  --font-super-huge: 13.375rem; // 214px
  --font-max-huge: 15.875rem; // 254px
  // ... xl xxl xxxl huge super-huge

  // Font weights
  --font-thin: 100;
  --font-light: 400;
  --font-regular: 600;
  --font-bold: 700;

  // Line height
  --leading-super-tight: 0.6875rem; // 11px
  --leading-3: 0.75rem; // 12px
  --leading-3\.5: 0.8125rem; //13px
  --leading-4: 1rem; // 16px
  --leading-4\.5: 1.125rem; // 18px
  --leading-5: 1.25rem; // 20px
  --leading-body: 1.4375rem; // 23px
  --leading-6: 1.5rem; // 24px
  --leading-6\.5: 1.625rem; // 26px
  --leading-7\.5: 1.875rem; // 30px
  --leading-9: 2.25rem; // 36px
  --leading-none: 1; // 16px
  --leading-tight: 1.25; // 20px
  --leading-snug: 1.375; // 22 px
  --leading-normal: 1.5; // 24px
  --leading-relaxed: 1.625; // 26px
  --leading-loose: 2; // 32px

  //Letter spacing
  --letter-spacing-huge-font-super-tight: -0.578rem; // -9.24px
  --letter-spacing-huge-font-tight: -0.284rem; // -4.55px
  --letter-spacing-small-font-tight: -0.075rem; // -1.02px
  --letter-spacing-tight: 0.072rem; // 1.15px
  --letter-spacing-medium: 0.086rem; // 1.38px
  --letter-spacing-normal: 0.098125rem; // 1.57px
  --letter-spacing-large: 0.106rem; // 1.69px
  --letter-spacing-xl: 0.11625rem; // 1.86px

  // Colors
  --black: #000000;
  --gray: #aaaaaa;
  --gray-25: #acacac;
  --gray-50: #f4f4f4;
  --gray-100: #d5d5d5;
  --gray-150: rgba(48, 48, 48, 0.8);
  --green: #6d9d54;
  --red: #cf2a26;
  --stone: #f0efea;
  --yellow: #f1c44b;
  --sand: #f2f1ee;
  --sand-25: #dfdcd4;
  --sand-50: #f9f8f6;
  --insiders: #d5e7e8;
  --itinerary-success: #81c060;
  --itinerary-warning: #ffbd4a;
  --itinerary-danger: #db0a0a;

  // Z-index
  --z-0: 0;
  --z-1: 1;
  --z-10: 10;
  --z-100: 100;
  --z-auto: auto;

  // Spacing
  --spacing-1: 0.25rem; // 4px
  --spacing-2: 0.5rem; // 8px
  --spacing-2\.5: 0.625rem; // 10px
  --spacing-3: 0.75rem;
  --spacing-3\.5: 0.8125rem; // 13px
  --spacing-3\.75: 0.9375rem; //15px
  --spacing-4: 1rem;
  --spacing-4\.5: 1.125rem; //18px
  --spacing-5: 1.25rem; // 20px
  --spacing-5\.5: 1.5rem; // 24px
  --spacing-7: 1.75rem;
  --spacing-7\.5: 1.875rem; // 30px
  --spacing-8: 2rem;
  --spacing-9: 2.25rem;
  --spacing-9\.5: 2.375rem; // 38px
  --spacing-10: 2.5rem;
  --spacing-11: 2.75rem; // 44px
  --spacing-12: 3rem; // 48px
  --spacing-12\.5: 3.125rem; // 50px
}
