@use '@angular/material' as mat;
@import 'variables';
@import 'material';
@import 'buttons';
@import 'flex';
@import 'ess_mixins';

/* You can add global styles to this file, and also import other style files */
:root {
  --white: white;
  --background-grey: whitesmoke;
  --border-grey: #d5d5d5;
  --light-grey: #d5d5d5;
  --lighter-grey: #f3f2f2;
  --dark-grey: #9b9b9b;
  --darker-grey: #9b9b9b;
  --light-black: #242323;
  --black: black;
  --text-black: #4a4a4a;
  --warn: #f05d5d;
  --error: #f44336;
  --blue: #39f;
  --light-blue: rgba(74, 144, 226, 0.36);
  --success: #7bc77b;
  --gold: #f1ba05;
}

a > * {
  color: var(--text-black);
  text-decoration: none;
}

p:default,
span:default {
  color: var(--text-black);
}

span.p-button-icon,
span.p-button-label {
  color: var(--text-black);
}

p,
span {
  font-family: var(--primary-font-family);
  font-size: mat.m2-font-size($custom-typography, body-1);
  line-height: mat.m2-line-height($custom-typography, body-1);
}

p {
  margin: 0.625rem 0;
}

h1,
h1 span {
  font-family: var(--primary-font-family);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.5rem;
  margin: 0.625rem 0 0 0;
}

h2,
h2 span {
  font-family: var(--primary-font-family);
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 1.875rem;
  margin: 0.5625rem 0 0 0;
}
h3,
h3 span {
  font-family: var(--primary-font-family);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin: 0.625rem 0 0.1875rem 0;
}

h4,
h4 span {
  font-family: var(--secondary-font-family) !important;
  font-size: 0.875rem;
  line-height: 0.875rem;
  margin: 0.4375rem 0 0.125rem 0;
  letter-spacing: 2.1px;
  text-transform: uppercase;

  &.condensed {
    font-family: var(--secondary-font-family-condensed) !important;
    font-weight: 400;
  }
}

h5,
h5 span {
  font-family: var(--secondary-font-family) !important;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 2.1px;
  line-height: 0.875rem;
  margin: 0.3125rem 0 0 0;
  text-transform: uppercase;
}

h6,
h6 span {
  font-family: var(--secondary-font-family) !important;
  font-size: 0.625rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  line-height: 0.625rem;
  margin: 0.25rem 0 0 0;
}

label {
  font-family: var(--primary-font-family) !important;
  font-style: italic !important;
  color: var(--text-black) !important;
}

input {
  font-family: var(--primary-font-family) !important;
  font-size: mat.m2-font-size($custom-typography, body-1) !important;
  line-height: mat.m2-line-height($custom-typography, body-1) !important;
}

.italic {
  font-style: italic;
}

@media (max-width: 599px) {
  p {
    font-size: 1rem;
  }

  h1 {
    font-size: 2.25rem;
  }

  h2 {
    font-size: 1.6825rem;
  }

  h3 {
    font-size: 1.125rem;
  }

  h4 {
    font-size: 0.875rem;
  }

  h5 {
    font-size: 0.875rem;
  }

  h6 {
    font-size: 0.625rem;
  }
}

/* LOGO */
.logo {
  width: 15rem;
}
.loading_logo {
  display: inline-block;
  position: absolute;
  line-height: 8rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -999;
}

.loading_spinner {
  border: 8px solid #9e9e9e4f;
  border-radius: 50%;
  height: 60px;
  left: 50%;
  margin: -30px 0 0 -30px;
  opacity: 0;
  position: absolute;
  top: 4rem;
  width: 60px;
  animation: pulsate 1.4s ease-out;
  animation-iteration-count: infinite;
  z-index: -998;
}

.error {
  background-color: var(--warn);
}

.success {
  background-color: var(--success);
}

.notification-info {
  background-color: var(--gold);
}

.pointer {
  cursor: pointer;
}

.clearfix {
  clear: both;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.exp-edit-dialog {
  .mat-tab-body-wrapper {
    padding: 1.25rem;
  }
  .mat-hint {
    padding-top: 0.3125rem;
  }
}
