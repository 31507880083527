/**
 Size
 */

// 8px
.text-super-tiny {
  font-size: var(--font-super-tiny);
}

// 9px
.text-tiny {
  font-size: var(--font-tiny);
}

// 10px
.text-xxxs {
  font-size: var(--font-xxxs);
}

// 11px
.text-xxs {
  font-size: var(--font-xxs);
}

// 12px
.text-xs {
  font-size: var(--font-xs);
}

// 13px
.text-s {
  font-size: var(--font-s);
}

// 14px
.text-sm {
  font-size: var(--font-sm);
}

// 15px
.text-normal {
  font-size: var(--font-normal);
}

// 16px
.text-base {
  font-size: var(--font-base);
}

// 18px
.text-m {
  font-size: var(--font-m);
}

// 16px
.font-base {
  font-size: var(--font-base);
}

// 20px
.text-l {
  font-size: var(--font-l);
}

.text-l-2 {
  font-size: var(--font-l-2);
}

// 44px
.font-2xl {
  font-size: var(--font-2xl);
}

// 60px
.font-pre-huge {
  font-size: var(--font-pre-huge);
}

/**
 Leading
 */

.leading-3 {
  line-height: var(--leading-3);
}

.leading-4 {
  line-height: var(--leading-4);
}

.leading-5 {
  line-height: var(--leading-5);
}

.leading-6\.5 {
  line-height: var(--leading-6\.5);
}

.leading-7\.5 {
  line-height: var(--leading-7\.5);
}

.leading-tight {
  line-height: var(--leading-tight);
}

.leading-normal {
  line-height: var(--leading-normal);
}

.leading-relaxed {
  line-height: var(--leading-relaxed);
}

/**
 Font Weight
 */

.font-thin {
  font-weight: var(--font-thin);
}

.font-light {
  font-weight: var(--font-light);
}

.font-bold {
  font-weight: var(--font-bold);
}

/**
 Font Family
 */

.font-primary {
  font-family: var(--primary-font-family);
}

.font-secondary {
  font-family: var(--secondary-font-family);
}

.font-secondary-bold {
  font-family: var(--secondary-font-family-bold);
}

/**
 Style
 */

.italic {
  font-style: italic;
}
