/**
 *  Spacing: padding and margin *
 */

// Padding
.p-0 {
  padding: 0;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-4\.5 {
  padding: 1.125rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-4\.5 {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-0 {
  padding-bottom: 0;
  padding-top: 0;
}

.py-2 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.py-3 {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.py-4 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.py-4\.5 {
  padding-bottom: 1.125rem;
  padding-top: 1.125rem;
}

.py-5 {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}

.py-6 {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-2\.5 {
  padding-top: 0.625rem; // 10px
}

.pt-3 {
  padding-top: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-4\.5 {
  padding-bottom: 1.125rem;
}

.pb-6 {
  padding-bottom: 1.5rem; // 24px
}

.pl-0 {
  padding-left: 0;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-4\.5 {
  padding-left: 1.125rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

// Margin
.m-0 {
  margin: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-0 {
  margin-bottom: 0;
  margin-top: 0;
}

.my-1\.5 {
  margin-bottom: 0.375rem;
  margin-top: 0.375rem;
}

.my-2\.5 {
  margin-bottom: 0.625rem; // 10px
  margin-top: 0.625rem;
}

.my-4 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.my-4\.5 {
  margin-bottom: 1.125rem;
  margin-top: 1.125rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-2\.5 {
  margin-top: 0.625rem; // 10px
}

.mt-4 {
  margin-top: 1rem; // 16px
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4\.5 {
  margin-right: 1.125rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-1\.5 {
  margin-bottom: 0.375rem;
}

.mb-2 {
  margin-bottom: 0.5rem; // 8px
}

.mb-2\.5 {
  margin-bottom: 0.625rem; // 10px
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem; // 16px
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.ml-2 {
  margin-left: 0.5rem; // 8px
}

.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem; // 16px
}

/* width and height */
.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.h-14 {
  height: 3.5rem;
}

.inline-block {
  display: inline-block;
}

/**
  Borders
 */
.border-gray-100 {
  border-color: var(--gray-100);
}

.border-b {
  border-bottom: 1px solid;
}

.border-l {
  border-left: 1px solid;
}

.border-r {
  border-right: 1px solid;
}
