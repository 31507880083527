@use '@angular/material' as mat;
@import 'variables';

.p-button {
  display: flex;
  justify-items: center;

  .p-button-label {
    padding-top: 3px;
  }

  &,
  .pi,
  span {
    align-self: center;
    font-size: mat.m2-font-size($custom-typography, body-1);
    line-height: 16px;
  }

  .pi {
    font-size: mat.m2-font-size($custom-typography, body-2);
  }

  &:not([class*='bg-']) {
    background-color: var(--white) !important;
    border-color: $primary-color !important;

    &:hover {
      background-color: var(--text-black) !important;
      &,
      span {
        color: white !important;
      }
    }
  }

  &[class*='bg-'] {
    &,
    span {
      color: white !important;
    }
  }

  &.bg-green {
    background-color: #5baa5b !important;
    border-color: #5baa5b !important;
  }

  &.bg-sand {
    background-color: #d5d6d2 !important;
    border-color: #d5d6d2 !important;
  }

  &.bg-orange {
    background-color: #ff8d3f !important;
    border-color: #ff8d3f !important;
  }

  &.bg-charcoal {
    background-color: #495b63 !important;
    border-color: #495b63 !important;
  }

  &.bg-ginger {
    background-color: #d35c37 !important;
    border-color: #d35c37 !important;
  }
}
