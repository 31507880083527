@use '@angular/material' as mat;
@import 'variables';
@import 'ess_font';

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$adminalist-primary: mat.m2-define-palette(mat.$m2-grey-palette);
$adminalist-accent: mat.m2-define-palette(mat.$m2-blue-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$adminalist-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$adminalist-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $adminalist-primary,
      accent: $adminalist-accent,
      warn: $adminalist-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($adminalist-theme);
@include mat.all-component-typographies();

/* MATERIAL OVERWRITES */
.mat-chip {
  font-family: var(--secondary-font-family);
}

.mat-input-element {
  font-size: mat.m2-font-size($custom-typography, body-1);
  line-height: mat.m2-line-height($custom-typography, body-1);
}

.mat-tab-label-active {
  color: var(--black);
}
.mat-tab-group,
.mat-tab-label,
.mat-tab-label .mat-tab-label-content h4 {
  @extend .text-xs;

  font-family: var(--secondary-font-family) !important;
}

.p-dropdown-label.p-inputtext {
  color: var(--text-black);
  font-family: var(--primary-font-family);
  font-size: mat.m2-font-size($custom-typography, body-1);
}

.p-input-icon-left .pi,
.p-input-icon-right .pi {
  font-size: mat.m2-font-size($custom-typography, body-2);
  left: 8px !important;
  margin-top: -8px;
  & + input {
    padding-left: 32px;
    padding-top: 8px;
  }
}

mat-error {
  font-family: var(--primary-font-family);
  font-size: 1rem;
  font-style: italic;
}

mat-icon[fontSet='fas'] {
  font-size: 1rem;
  height: 1rem !important;
  margin-right: 0.625rem;
  text-align: center;
  width: 1rem !important;
}

mat-card {
  border-radius: 0;
  box-shadow:
    0px 0px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 2px 0px rgba(0, 0, 0, 0.12);

  .mat-card-subtitle h4 {
    @extend .text-xs;

    font-family: var(--secondary-font-family) !important;
  }
}

.mat-optgroup-label {
  font-size: 1.25rem !important;
  text-transform: capitalize !important;
}

.snack-wrapline {
  white-space: pre-wrap;
}

.custom-mat-dialog .mat-dialog-container {
  max-height: 100% !important;
  padding: 0 24px !important;
}

mat-form-field {
  margin-top: 0.5rem;
}

@media screen and (min-height: 1280px) {
  .custom-mat-dialog {
    height: 90vh !important;
  }
}

@media screen and (min-width: 1280px) {
  .custom-mat-dialog {
    max-width: 80vw !important;
    min-width: 80vw !important;
    width: auto;
  }
}

@media screen and (min-width: 1440px) {
  .custom-mat-dialog {
    max-width: 1280px !important;
    min-width: 1280px !important;
    width: 100%;
  }
}
