/**
Text color
 */

.text-light {
  color: var(--light-text-color);
}

.text-primary {
  color: var(--primary-text-color);
}

.text-secondary {
  color: var(--secondary-text-color);
}

.text-gray {
  color: var(--terciary-text-color);
}

.text-gray-100 {
  color: var(--gray-100);
}

/**
Background
*/
.bg-stone {
  background-color: var(--stone);
}

.bg-primary-text {
  background-color: var(--primary-text-color);
}
