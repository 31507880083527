@import 'ess_default-theme';

form,
.form {
  .component-wrapper {
    display: flex;
    position: relative;
  }

  .p-component,
  .p-inputtext {
    font-family: var(--primary-font-family);
    font-size: var(--font-l);
  }

  /*** Input text */
  .p-inputtext,
  .p-inputtext:enabled,
  .p-inputtext:focus,
  .p-inputtext:hover,
  .p-inputtext:enabled:focus,
  .p-inputtext.error {
    border-bottom: 1px solid;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    box-shadow: none;
    color: var(--secondary-text-color);
    padding-left: 0;
    padding-right: 0;
    transition: all ease-in-out;
  }

  .p-inputtext:not(.p-disabled):not(:disabled),
  .p-inputtext:not(.p-disabled):not(:disabled):enabled {
    border-color: var(--secondary-border-color);
  }

  .p-inputtext:not(.p-disabled):not(:disabled):focus,
  .p-inputtext:not(.p-disabled):not(:disabled):hover,
  .p-inputtext:not(.p-disabled):not(:disabled):enabled:focus {
    border-color: var(--primary-border-color);
    color: var(--primary-text-color);

    &.error:not(.p-disabled):not(:disabled) {
      border-color: var(--error-border-color);
    }
  }

  /** Errors */
  .is-invalid {
    color: var(--error-text-color);

    input {
      border-color: var(--error-border-color) !important;
    }

    &::ng-deep input {
      border-color: var(--error-border-color) !important;
    }
  }
}
