@import './ess_default-theme';
@import './ess_font-family';
@import './ess_font';
@import './ess_color';
@import './ess_mixins';
@import './ess_form';

html,
body {
  box-sizing: border-box;
  color: var(--primary-text-color);
  display: block;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  margin: 0;
  overflow: visible;
  position: relative;
  width: 100%;
}

body,
body a,
body div,
body h1,
body h2,
body h3,
body h4,
body h5,
body p {
  &:not(i),
  &:not(mat-icon),
  &:not(p-button),
  &:not(.pi),
  &:not([mat-dialog-close]),
  &:not(.mat-icon),
  &:not(.p-button) {
    font-family: var(--primary-font-family);
  }
}

body .p-button:not(.font-primary),
body .p-button:not(.font-primary) .p-button-label {
  font-family: var(--secondary-font-family-condensed);
  font-weight: 400;
}

body i {
  font-family: var(--primary-italic-font-family);
}

img {
  max-width: 100%;
}

.wrapper {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 20px;
  width: 100%;
}
