.flex {
  display: flex;

  &.flex-auto > * {
    flex: 1 auto;
  }

  .align-center {
    align-self: center;
  }
}

.align-end {
  align-items: flex-end;
}

.justify-between {
  justify-content: space-between;
}
